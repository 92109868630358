import React, { useCallback, useRef } from "react";
import { useEffect, useState } from "react";
import { Book } from "../types";
import BookCard from "./BookCard";

const MAX_PAGE_NUM = 5;

export default function App() {
  const [books, setBooks] = useState<Book[]>([]);

  const fetchedPages = useRef(0);

  const loadMoreItems = useCallback(
    async function loadMoreItems() {
      if (fetchedPages.current >= MAX_PAGE_NUM) return;
      const pageNum = fetchedPages.current + 1;
      // console.log(`!!! Fetching page ${pageNum}`);
      const response = await fetch(`/data/top/page${pageNum}.json`);
      const page = await response.json();
      setBooks((prevBooks) => prevBooks.concat(page));
      fetchedPages.current += 1;
    },
    [setBooks]
  );

  const scrollSentinel = useRef(null);

  // Register infinite scroll sentinel observer
  useEffect(() => {
    if (scrollSentinel.current) {
      const observer = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          loadMoreItems();
        }
      }, {});
      observer.observe(scrollSentinel.current);
    }
  }, [scrollSentinel, loadMoreItems]);

  return (
    <div
      style={{
        backgroundColor: "black",
        fontFamily: "courier new, monospace",
        color: "white",
        padding: "10px",
        maxWidth: "800px",
        margin: "auto",
      }}
    >
      <h1 style={{ marginBottom: "5px" }}>Controversial Now</h1>
      <p style={{ marginTop: "5px", marginBottom: "25px" }}>
        Ranking of the most controversial books based on online ratings.
      </p>
      {books.map((book, i) => (
        <BookCard book={book} rank={i + 1} key={book.cover} />
      ))}
      <div ref={scrollSentinel} className="sentinel"></div>
    </div>
  );
}
