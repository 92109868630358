import React from "react";
import { Book } from "../types";
import "./BookCard.css";

interface BookCardProps {
  book: Book;
  rank: number;
}

export default function BookCard({ book, rank }: BookCardProps) {
  return (
    <div style={{ display: "flex", marginTop: "10px" }}>
      <div style={{ width: "55px", flexShrink: 0 }}>
        <h2>{rank}.</h2>
      </div>
      <div style={{ flexGrow: 0 }}>
        <img
          style={{
            minWidth: "150px",
            maxWidth: "150px",
            minHeight: "150px",
            borderRadius: "0px 5px 5px 0px",
            filter: 'drop-shadow(0 0 2px rgba(255, 255, 255, 0.2))',
          }}
          src={`/covers/${book.cover}.jpg`}
          alt={book.title}
        />
      </div>
      <div>
        <h2>{book.title}</h2>
        <h3>by {book.author}, {book.year}</h3>
        <p>{book.description}</p>
      </div>
    </div>
  );
}
